import React, { useState } from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import ArrowCircleLeftTwoToneIcon from "@mui/icons-material/ArrowCircleLeftTwoTone";

import cuadro_1 from "./../pics/art/oils/cuadro_1.webp";
import cuadro_2 from "./../pics/art/oils/cuadro_2.webp";
import cuadro_3 from "./../pics/art/oils/cuadro_3.webp";
import cuadro_4 from "./../pics/art/oils/cuadro_4.webp";
import cuadro_5 from "./../pics/art/oils/cuadro_5.webp";
import cuadro_6 from "./../pics/art/oils/cuadro_6.webp";
import cuadro_7 from "./../pics/art/oils/cuadro_7.webp";
import cuadro_8 from "./../pics/art/oils/cuadro_8.webp";
import cuadro_9 from "./../pics/art/oils/cuadro_9.webp";
import cuadro_10 from "./../pics/art/oils/cuadro_10.webp";
import cuadro_11 from "./../pics/art/oils/cuadro_11.webp";
import cuadro_12 from "./../pics/art/oils/cuadro_12.webp";
import cuadro_13 from "./../pics/art/oils/cuadro_13.webp";
import cuadro_14 from "./../pics/art/oils/cuadro_14.webp";
import cuadro_15 from "./../pics/art/oils/cuadro_15.webp";

import series_1 from "./../pics/art/series/cuadro_1.webp";
import series_2 from "./../pics/art/series/cuadro_2.webp";
import series_3 from "./../pics/art/series/cuadro_3.webp";
import series_4 from "./../pics/art/series/cuadro_4.webp";
import series_5 from "./../pics/art/series/cuadro_5.webp";
import series_6 from "./../pics/art/series/cuadro_6.webp";
import series_7 from "./../pics/art/series/cuadro_7.webp";
import series_8 from "./../pics/art/series/cuadro_8.webp";

import sc_1 from "./../pics/art/sculptures/sc_1.webp";
import sc_2 from "./../pics/art/sculptures/sc_2.webp";
import sc_3 from "./../pics/art/sculptures/sc_3.webp";
import sc_4 from "./../pics/art/sculptures/sc_4.webp";
import sc_5 from "./../pics/art/sculptures/sc_5.webp";
import sc_6 from "./../pics/art/sculptures/sc_6.webp";
import sc_7 from "./../pics/art/sculptures/sc_7.webp";
import sc_8 from "./../pics/art/sculptures/sc_8.webp";
import sc_9 from "./../pics/art/sculptures/sc_9.webp";
import sc_10 from "./../pics/art/sculptures/sc_10.webp";
import sc_11 from "./../pics/art/sculptures/sc_11.webp";
import sc_12 from "./../pics/art/sculptures/sc_12.webp";
import sc_13 from "./../pics/art/sculptures/sc_13.webp";
import sc_14 from "./../pics/art/sculptures/sc_14.webp";
import sc_15 from "./../pics/art/sculptures/sc_15.webp";

export const Art = () => {
  const [settings] = useUserSettings();
  const { language } = settings;
  const oils = [
    { c: cuadro_1, t: i18n({ index: "asombro", language }) },
    { c: cuadro_2, t: "Luxor (1996)" },
    { c: cuadro_3, t: i18n({ index: "tetera", language }) },
    { c: cuadro_4, t: i18n({ index: "coffee_yellow", language }) },
    { c: cuadro_5, t: i18n({ index: "fish", language }) },
    { c: cuadro_6, t: i18n({ index: "daniel", language }) },
    { c: cuadro_7, t: i18n({ index: "cup", language }) },
    { c: cuadro_8, t: i18n({ index: "coffee_white", language }) },
    { c: cuadro_9, t: i18n({ index: "cup2", language }) },
    { c: cuadro_10, t: i18n({ index: "ale_luxor", language }) },
    { c: cuadro_11, t: i18n({ index: "red", language }) },
    { c: cuadro_12, t: i18n({ index: "ale_turista", language }) },
    { c: cuadro_13, t: i18n({ index: "abusimbel", language }) },
    { c: cuadro_14, t: i18n({ index: "corruption", language }) },
    { c: cuadro_15, t: i18n({ index: "cyan", language }) },
  ];

  const series = [
    { c: series_1, t: "El Turco" },
    { c: series_2, t: "Mantequita Sollozon" },
    { c: series_3, t: i18n({ index: "japan", language }) },
    { c: series_4, t: "Malevo" },
    { c: series_5, t: "El Gallego" },
    { c: series_6, t: i18n({ index: "cook", language }) },
    { c: series_7, t: "Carnaval" },
    { c: series_8, t: i18n({ index: "model", language }) },
  ];

  const sculptures = [
    { c: sc_1, t: i18n({ index: "sc_1", language }) },
    { c: sc_2, t: i18n({ index: "sc_2", language }) },
    { c: sc_3, t: i18n({ index: "sc_3", language }) },
    { c: sc_4, t: i18n({ index: "sc_3", language }) },
    { c: sc_5, t: i18n({ index: "sc_4", language }) },
    { c: sc_6, t: '"Cacharro"' },
    { c: sc_7, t: i18n({ index: "sc_5", language }) },
    { c: sc_8, t: i18n({ index: "sc_6", language }) },
    { c: sc_9, t: i18n({ index: "sc_7", language }) },
    { c: sc_10, t: i18n({ index: "sc_8", language }) },
    { c: sc_11, t: i18n({ index: "sc_9", language }) },
    { c: sc_12, t: i18n({ index: "sc_3", language }) },
    { c: sc_13, t: i18n({ index: "sc_10", language }) },
    { c: sc_14, t: i18n({ index: "sc_11", language }) },
    { c: sc_15, t: i18n({ index: "sc_12", language }) },
  ];

  const [index, setIndex] = useState(0);
  const [indexTwo, setIndexTwo] = useState(0);
  const [indexThree, setIndexThree] = useState(0);

  console.log({ settings });

  return (
    <div className="background">
      <div className="pagetitle">{i18n({ index: "my_art", language })}</div>
      <div className="block">
        <h1 className="pagesubtitle">{i18n({ index: "oils", language })}</h1>
        <div className="divi">
          {index > 0 ? (
            <button
              className="art_left_button"
              onClick={() => setIndex((index) => index - 1)}
            >
              <ArrowCircleLeftTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          ) : (
            <button className="art_disabled_button">
              <ArrowCircleLeftTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          )}
          <div className="example">
            <img className="image" src={oils[index].c} alt=""></img>
            <div class="content">
              <text class="arttext">{oils[index].t}</text>
            </div>
          </div>
          <div>
            <button
              className="right_button"
              onClick={() => setIndex((index) => (index < 14 ? index + 1 : 0))}
            >
              <ArrowCircleRightTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          </div>
        </div>
      </div>

      <div className="block">
        <h1 className="pagesubtitle">{i18n({ index: "series", language })}</h1>
        <div className="divi">
          {indexTwo > 0 ? (
            <button
              className="art_left_button"
              onClick={() => setIndexTwo((indexTwo) => indexTwo - 1)}
            >
              <ArrowCircleLeftTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          ) : (
            <button className="art_disabled_button">
              <ArrowCircleLeftTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          )}
          <div className="example">
            <img className="image" src={series[indexTwo].c} alt=""></img>
            <div class="content">
              <text class="arttext">{series[indexTwo].t}</text>
            </div>
          </div>
          <div>
            <button
              className="right_button"
              onClick={() =>
                setIndexTwo((indexTwo) => (indexTwo < 7 ? indexTwo + 1 : 0))
              }
            >
              <ArrowCircleRightTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          </div>
        </div>
      </div>

      <div className="block">
        <h1 className="pagesubtitle">
          {i18n({ index: "sculptures", language })}
        </h1>
        <div className="divi">
          {indexThree > 0 ? (
            <button
              className="art_left_button"
              onClick={() => setIndexThree((indexThree) => indexThree - 1)}
            >
              <ArrowCircleLeftTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          ) : (
            <button className="art_disabled_button">
              <ArrowCircleLeftTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          )}
          <div className="example">
            <img className="image" src={sculptures[indexThree].c} alt=""></img>
            <div class="content">
              <text class="arttext">{sculptures[indexThree].t}</text>
            </div>
          </div>
          <div>
            <button
              className="right_button"
              onClick={() =>
                setIndexThree((indexThree) =>
                  indexThree < 14 ? indexThree + 1 : 0
                )
              }
            >
              <ArrowCircleRightTwoToneIcon
                style={{ color: "#fa198b", fontSize: 28 }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
