import { useUserSettings } from "../user";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "./../pics/logo.webp";
import { i18n } from "../i18n";
import { isMobile } from "react-device-detect";

export const Footer = () => {
  const [settings, setSettings] = useUserSettings();
  const { language } = settings;

  const setEnglish = () => {
    setSettings({ ...settings, language: "en" });
  };

  const setSpanish = () => {
    setSettings({ ...settings, language: "es" });
  };

  const setFrench = () => {
    setSettings({ ...settings, language: "fr" });
  };

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const MobileView = () => {
    return (
      <div className="footer_mobile">
        <img src={logo} alt="" className="logofooter"></img>
        <div className="callme">
          <text>{i18n({ index: "call_me", language })}</text>
          <text>+598 96 858 404</text>
        </div>

        <div className="languages_mobile">
          <button onClick={() => setEnglish()} className="language">
            English
          </button>
          <button onClick={() => setSpanish()} className="language">
            Español
          </button>
          <button onClick={() => setFrench()} className="language">
            Français
          </button>
        </div>
        <div className="footer_icons_mobile">
          <a href="https://uy.linkedin.com/in/myriamekhauzer">
            <button className="footer_icon">
              <LinkedInIcon style={{ color: "#fff" }} />
            </button>
          </a>
          <button className="footer_icon_email">
            <Mailto
              email="myriam.ekhauzer@outlook.com"
              subject="Arte y Design"
              body=""
            >
              <EmailOutlinedIcon style={{ color: "#fff" }} />
            </Mailto>
          </button>
          <a href="https://m.facebook.com/ekhauzer/">
            <button className="footer_icon">
              <FacebookOutlinedIcon style={{ color: "#fff" }} />
            </button>
          </a>
          <a href="https://instagram.com/lamonamyr?igshid=MzRlODBiNWFlZA==">
            <button className="footer_icon">
              <InstagramIcon style={{ color: "#fff" }} />
            </button>
          </a>
        </div>
        <div className="copyright_mobile">
          <text>Copyright © 2023 Arte y Design. All rights reserved.</text>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="footer">
        <div className="footer_left">
          <img src={logo} alt="" className="logofooter"></img>
          <div className="callme">
            <text>{i18n({ index: "call_me", language })}</text>
            <text>+598 96 858 404</text>
          </div>
        </div>
        <div className="copyright">
          <text>Copyright © 2023 Arte y Design. All rights reserved.</text>
        </div>
        <div className="footer_right">
          <div className="languages">
            <button onClick={() => setEnglish()} className="language">
              English
            </button>
            <button onClick={() => setSpanish()} className="language">
              Español
            </button>
            <button onClick={() => setFrench()} className="language">
              Français
            </button>
          </div>
          <div className="footer_icons">
            <a href="https://uy.linkedin.com/in/myriamekhauzer">
              <button className="footer_icon">
                <LinkedInIcon style={{ color: "#fff" }} />
              </button>
            </a>
            <button className="footer_icon_email">
              <Mailto
                email="myriam.ekhauzer@outlook.com"
                subject="Arte y Design"
                body=""
              >
                <EmailOutlinedIcon style={{ color: "#fff" }} />
              </Mailto>
            </button>
            <a href="https://m.facebook.com/ekhauzer/">
              <button className="footer_icon">
                <FacebookOutlinedIcon style={{ color: "#fff" }} />
              </button>
            </a>
            <a href="https://instagram.com/lamonamyr?igshid=MzRlODBiNWFlZA==">
              <button className="footer_icon">
                <InstagramIcon style={{ color: "#fff" }} />
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
