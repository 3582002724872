import React from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

export const Home = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  console.log({ settings });

  const MobileView = () => {
    return (
      <div className="home">
        <div className="squares">
          <Link className="square_a_mobile" to={"/strategy"}>
            <div>{i18n({ index: "digital_design", language })}</div>
          </Link>
          <Link className="square_b_mobile" to={"/strategy"}>
            <div>{i18n({ index: "print_design", language })}</div>
          </Link>
        </div>
        <div className="squares">
          <Link className="square_b_mobile" to={"/strategy"}>
            <div>{i18n({ index: "brand_identity", language })}</div>
          </Link>
          <Link className="square_a_mobile" to={"/strategy"}>
            <div>{i18n({ index: "art_direction", language })}</div>
          </Link>
        </div>
        <div className="hometext">{i18n({ index: "home_text", language })}</div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="home">
        <div className="squares">
          <Link className="square_a" to={"/strategy"}>
            <div>{i18n({ index: "digital_design", language })}</div>
          </Link>
          <Link className="square_b" to={"/strategy"}>
            <div>{i18n({ index: "print_design", language })}</div>
          </Link>
        </div>
        <div className="squares">
          <Link className="square_b" to={"/strategy"}>
            <div>{i18n({ index: "brand_identity", language })}</div>
          </Link>
          <Link className="square_a" to={"/strategy"}>
            <div>{i18n({ index: "art_direction", language })}</div>
          </Link>
        </div>
        <div className="hometext">{i18n({ index: "home_text", language })}</div>
      </div>
    );
  };

  return (
    <div className="home">{isMobile ? <MobileView /> : <DesktopView />}</div>
  );
};
