import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useUserSettings } from "../user";
import { i18n } from "../i18n";
import { isMobile } from "react-device-detect";
import logo from "./../pics/logo.webp";

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [settings] = useUserSettings();
  const { language } = settings;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const MobileView = () => {
    return (
      <div className="navbarclosedmobile">
        <button onClick={toggle} className="icon">
          <MenuIcon style={{ color: "#fff" }} />
        </button>
        <div>
          <div className="navtitlemobile">Arte & Design</div>
          <div>{i18n({ index: "arte_title", language })}</div>
        </div>
        <img src={logo} alt="" className="logonavclosed"></img>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="navbarclosed">
        <button onClick={toggle} className="icon">
          <MenuIcon style={{ color: "#fff" }} />
        </button>
        <div>
          <div className="navtitle">Arte & Design</div>
          <div>{i18n({ index: "arte_title", language })}</div>
        </div>
        <img src={logo} alt="" className="logonavclosed"></img>
      </div>
    );
  };

  return isOpen ? (
    <div className="navbaropen">
      <div className="options">
        <button onClick={() => toggle()} className="icon">
          <CloseIcon style={{ color: "#fff" }} />
        </button>
        <img src={logo} alt="" className="logonavopen"></img>
        <Link className="option" to={"/"} onClick={toggle}>
          {i18n({ index: "home", language })}
        </Link>
        <Link className="option" to={"/strategy"} onClick={toggle}>
          {i18n({ index: "strategy", language })}
        </Link>
        <Link className="option" to={"/projects"} onClick={toggle}>
          {i18n({ index: "projects", language })}
        </Link>
        <Link className="option" to={"/art"} onClick={toggle}>
          {i18n({ index: "my_art", language })}
        </Link>
        <Link className="option" to={"/contact"} onClick={toggle}>
          {i18n({ index: "contact", language })}
        </Link>
      </div>
    </div>
  ) : isMobile ? (
    <MobileView />
  ) : (
    <DesktopView />
  );
};
