export const i18n = ({ index, language }) => {
  const translations = {
    arte_title: {
      en: "Creative Design Solutions",
      es: "Soluciones de diseño creativas",
      fr: "Solutions créatives en design",
    },
    welcome: {
      en: "Welcome",
      es: "Hola",
      fr: "Salut",
    },
    home: {
      en: "Home",
      es: "Inicio",
      fr: "Accueil",
    },
    projects: {
      en: "Projects",
      es: "Trabajos",
      fr: "Travaux",
    },
    branding: {
      en: "Branding",
      es: "Marcas",
      fr: "Marques",
    },
    strategy: {
      en: "Strategy",
      es: "Estrategia",
      fr: "Stratégies",
    },
    my_art: {
      en: "Fine Arts",
      es: "Bellas Artes",
      fr: "Beaux Arts",
    },
    contact: {
      en: "Contact",
      es: "Contacto",
      fr: "Contact",
    },
    oils: {
      en: "Oil Paintings",
      es: "Pinturas al óleo",
      fr: "Peintures à l'huile",
    },
    series: {
      en: "The Argentine Prejudices (Series)",
      es: "Los Prejuicios Argentinos (serie)",
      fr: "Les Préjugés Argentins (série)",
    },
    sculptures: {
      en: "Ceramic Sculptures",
      es: "Esculturas en cerámica",
      fr: "Sculptures en céramique",
    },
    asombro: {
      en: "Shock (2017)",
      es: "Asombro (2017)",
      fr: "Étonnement (2017)",
    },
    tetera: {
      en: "Two-Tone Teapot (2018)",
      es: "Tetera Bitona (2018)",
      fr: "Théière bicolore (2018)",
    },
    coffee_yellow: {
      en: "Yellow Coffee - Cup Series (2004)",
      es: "Café Amarillo - Serie Tazas (2004)",
      fr: "Café Jaune - Série Tasses (2004)",
    },
    fish: {
      en: "Fish (2016)",
      es: "Peces (2016)",
      fr: "Poissons (2016)",
    },
    daniel: {
      en: "Daniel Left Again (2018)",
      es: "Y Daniel se fue otra vez (2018)",
      fr: "Daniel est parti à nouveau (2018)",
    },
    cup: {
      en: "Would you like a cup? - Cup Series (2004)",
      es: "¿Te gustaría una taza? - Serie Tazas (2004)",
      fr: "Voulez-vous une tasse? - Série Tasses (2004)",
    },
    coffee_white: {
      en: "Coffee in White - Cup Series (2004)",
      es: "Café en Blanco - Serie Tazas (2004)",
      fr: "Café en Blanc - Série Tasses (2004)",
    },
    cup2: {
      en: "Cup - Cup Series (2004)",
      es: "Taza - Serie Tazas (2004)",
      fr: "Tasse - Série Tasses (2004)",
    },
    ale_luxor: {
      en: "Ale in Luxor - Love for Egypt Series (199)",
      es: "Ale en Luxor - Serie Amor por Egipto (199)",
      fr: "Ale à Luxor - Série Amour pour l'Égypte (199)",
    },
    red: {
      en: "Red - Love for Egypt Series (199)",
      es: "Rojo - Serie Amor por Egipto (199)",
      fr: "Rouge - Série Amour pour l'Égypte (199)",
    },
    ale_turista: {
      en: "Ale the Tourist - Love for Egypt Series (199)",
      es: "Ale Turista - Serie Amor por Egipto (199)",
      fr: "Ale Touriste - Série Amour pour l'Égypte (199)",
    },
    abusimbel: {
      en: "Abusimbel - Love for Egypt Series (199)",
      es: "Abusimbel - Serie Amor por Egipto (199)",
      fr: "Abusimbel - Série Amour pour l'Égypte (199)",
    },
    corruption: {
      en: "Argentine Corruption (199)",
      es: "Corrupción Argentina (199)",
      fr: "Corruption Argentine (199)",
    },
    cyan: {
      en: "Cyan Coffee - Cup Series (2004)",
      es: "Café Cian - Serie Tazas (2004)",
      fr: "Café Cyan - Série Tasses (2004)",
    },
    japan: {
      en: "The Lunatic Japanese",
      es: "El Japonés Lunático",
      fr: "Le Japonais Fou",
    },
    cook: {
      en: "Cook",
      es: "Cocinero",
      fr: "Cuisinier",
    },
    model: {
      en: "Model",
      es: "Modelo",
      fr: "Modèle",
    },
    sc_1: {
      en: "Plates",
      es: "Platos",
      fr: "Plats",
    },
    sc_2: {
      en: "Teapot",
      es: "Tetera",
      fr: "Théière",
    },
    sc_3: {
      en: "Vase",
      es: "Jarrón",
      fr: "Vase",
    },
    sc_4: {
      en: "Small bowls",
      es: "Compoteras",
      fr: "Petits bols",
    },
    sc_5: {
      en: "Loose",
      es: "Floja",
      fr: "Lâche",
    },
    sc_6: {
      en: "Plate",
      es: "Plato",
      fr: "Plat",
    },
    sc_7: {
      en: "Bowl",
      es: "Bowl",
      fr: "Bol",
    },
    sc_8: {
      en: "Snack Set",
      es: "Copetinero",
      fr: "Ensemble de collations",
    },
    sc_9: {
      en: "The Eye",
      es: "El Ojo",
      fr: "L'œil",
    },
    sc_10: {
      en: "Orange",
      es: "Naranja",
      fr: "Orange",
    },
    sc_11: {
      en: "Snake-Duck",
      es: "Víbora-Pato",
      fr: "Serpent-Canard",
    },
    sc_12: {
      en: "Fish",
      es: "Pescados",
      fr: "Poissons",
    },
    visit: {
      en: "Visit",
      es: "Visitar",
      fr: "Visiter",
    },
    corniche_t: {
      en: "Special Program",
      es: "Programa Especial",
      fr: "Programme Spécial",
    },
    corniche_d: {
      en: "Designed by Myriam Ekhauzer for restaurant La Corniche in Piriápolis, Uruguay. Social media poster.",
      es: "Diseñado por Myriam Ekhauzer para Restaurant La Corniche en Piriápolis, Uruguay. Poster para redes sociales.",
      fr: "Conçu par Myriam Ekhauzer pour le restaurant La Corniche à Piriapolis, en Uruguay. Affiche pour les réseaux sociaux.",
    },
    corniche3_t: {
      en: "Special Menu",
      es: "Menú Especial",
      fr: "Menu Spécial",
    },
    corniche3_d: {
      en: "Designed by Myriam Ekhauzer for restaurant La Corniche in Piriápolis, Uruguay.",
      es: "Diseñado por Myriam Ekhauzer para Restaurant La Corniche en Piriápolis, Uruguay.",
      fr: "Conçu par Myriam Ekhauzer pour le restaurant La Corniche à Piriapolis, en Uruguay.",
    },
    ometz_t: {
      en: "Celebration Poster",
      es: "Poster Conmemorativo",
      fr: "Affiche célébratoire",
    },
    ometz_d: {
      en: "Created to commemorate Montreal's Jewish Community Services organization Ometz's 150th year anniversay. Both the photography session and conception of image were directed by Myriam Ekhauzer.",
      es: "Imagen creada para conmemorar el 150 aniversario de Servicios de Ometz, la comunidad Judía de Montreal. Tanto la sesión fotográfica como la concepción de la imagen estuvieron a cargo de Myriam Ekhauzer.",
      fr: "Créée pour célebrer les 150 ans de services de la communauté juive de Montréal Ometz. La session photographique comme la conception de l'image furent dirigées par Myriam Ekhauzer.",
    },
    dentista_t: {
      en: "Dentist",
      es: "Dentista",
      fr: "Dentiste",
    },
    dentista_d: {
      en: "Facebook post for an orthodontics clinic.",
      es: "Aviso de Facebook para clínica de ortodoncia.",
      fr: "Publicité Facebook pour une clinique d'orthodoncie.",
    },
    card_t: {
      en: "Business Card",
      es: "Tarjeta",
      fr: "Carte Professionelle",
    },
    card_d: {
      en: "Designs for Business Cards in Florida, USA.",
      es: "Diseño de tarjetas profesionales en Estados Unidos.",
      fr: "Conception pour una carte de présentation en Floride.",
    },
    card2_d: {
      en: "For Innovate Fire Corp.",
      es: "Para Innovate Fire Corp.",
      fr: "Pour Innovate Fire Corp.",
    },
    disco_t: {
      en: "Disco",
      es: "Disco",
      fr: "Disco",
    },
    disco_d: {
      en: "Vintage advertising postcard for Moondance Disco in Argentina.",
      es: "Poster publicitario vintage para Disco Moondance en Argentina.",
      fr: "Carte de publicité pour une discothèque en Argentine.",
    },
    menu_t: {
      en: "Menu",
      es: "Menú",
      fr: "Menu",
    },
    menu_d: {
      en: "Graphic and web design for restaurant menus.",
      es: "Diseño web y gráfico para menus de restaurantes.",
      fr: "Design graphique et web pour les menus des restaurants.",
    },
    menu2_t: {
      en: "More menus",
      es: "Más menús",
      fr: "Plus de menus",
    },
    menu2_d: {
      en: "Graphic and web design for restaurant menus - another example.",
      es: "Diseño web y gráfico para menus de restaurantes - otro ejemplo.",
      fr: "Design graphique et web pour les menus des restaurants - un autre exemple.",
    },
    packaging_t: {
      en: "Packaging",
      es: "Packaging",
      fr: "Packaging",
    },
    packaging_d: {
      en: "ViCap children's game packaging design in Argentina.",
      es: "Diseño de packaging para juegos para niños ViCap en Argentina.",
      fr: "Design pour les jeux d'enfants ViCap en Argentine.",
    },
    pulse_t: {
      en: "Pulse Magazine",
      es: "Reviste Pulse",
      fr: "Magazine Pulse",
    },
    pulse_d: {
      en: "Digital and printed design for Montreal's Jewish General Hospital magazine.",
      es: "Diseño de la edición impresa y digital de la revista del Hôpital Général Juif de Montréal.",
      fr: "Conception des versions numériques et physiques de la revue de l'Hôpital Général Juif de Montreal.",
    },
    relieve_t: {
      en: "Letterhead",
      es: "Letterhead",
      fr: "Letterhead",
    },
    relieve_d: {
      en: "Embossed letterhead for Smatts Design in Hollywood, FL.",
      es: "Letterhead impreso en bajorelieve para Smatts Design en Estados Unidos.",
      fr: "Letterhead imprimé en relief pour Smatts Design à Hollywood, Floride.",
    },
    torrecillas_t: {
      en: "The Sea",
      es: "El Mar",
      fr: "La Mer",
    },
    torrecillas_d: {
      en: "Facebook post and web design for Torrecillas Cariló Hotel.",
      es: "Aviso de Facebook y diseño web para el hotel Torrecillas Cariló.",
      fr: "Publicité Facebook et conception du site web pour l'hôtel Torrecillas Cariló.",
    },
    mama_t: {
      en: "Hi, it's Myriam!",
      es: "¡Hola, soy Myriam!",
      fr: "Bonjour, c'est Myriam!",
    },
    mama_d: {
      en: "Ometz agency's 150th anniversay sign designed by Myriam Ekhauzer.",
      es: "Cartel diseñado por Myriam Ekhauzer para conmemorar los 150 años de la agencia Ometz en Montreal.",
      fr: "Panneau conçu par Myriam Ekhauzer pour célébrer les 150 ans de l'agence Ometz à Montréal.",
    },
    brz_d: {
      en: "Logo and website for BRZ Capital Group.",
      es: "Diseño de logo y sitioweb para BRZ Capital Group.",
      fr: "Logo et siteweb pour BRZ Capital Group",
    },
    phoenix_d: {
      en: "Website for Phoenix Electronic Repair Services.",
      es: "Sitioweb para centro de reparaciones electónicas Phoenix.",
      fr: "Site web pour centre de réparations électroniques Phoenix.",
    },
    halah_d: {
      en: "Website for Halah Gourmet Foods.",
      es: "Sitioweb para Halah Alimentos Gourmet.",
      fr: "Site web pour Halah Aliments Gourmet.",
    },
    projects_one: {
      en: "Over the years, I've had the opportunity to work with a wide range of clients and projects around the world. Here are a few examples of ideas that we can design for you:",
      es: "A lo largo de los años, he tenido la oportunidad de trabajar con todo tipo de clientes y proyectos. Estos son algunos ejemplos de diseños que te podemos ofrecer:",
      fr: "Grâce à mes années d'expérience, je continue d'avoir la chance de travailler avec de très divers clients et projets. Voici quelques idées de désign qu'on pourrait concevoir pour vous:",
    },
    question: {
      en: "Ready to bring your idea to life?",
      es: "¿Quiere ver su idea hecha realidad?",
      fr: "Êtes-vous prêts à convertir votre idée en realité?",
    },
    contact_us: {
      en: "Contact Us!",
      es: "¡Póngase en contacto!",
      fr: "Contactez-nous!",
    },
    list_item_one: {
      en: "Websites, e-commerce, blogs, app icons.",
      es: "Sitios web, comercios online, blogs, íconos para aplicaciones.",
      fr: "Sites web, e-commerce, blogs, icônes d'applications.",
    },
    list_item_two: {
      en: "Social Media Content (including Facebook, Instagram, X, etc.).",
      es: "Contenido para redes (incluyendo Facebook, Instagram, X, y otros).",
      fr: "Contenu pour les réseaux sociaux (comme Facebook, Instagram, X, et autres).",
    },
    list_item_three: {
      en: "Logos, logotypes and corporate image.",
      es: "Logos, logotipos, imagen corporativa.",
      fr: "Logos, logotypes, image de l'entreprise.",
    },
    list_item_four: {
      en: "Interior/exterior signs, banners, exhibitors, stands, etc.",
      es: "Carteles, pósters, exhibidores, stands, etc. para interior y exterior.",
      fr: "Panneaux, affiches, exposants, stands, etc. intérieurs/extérieurs.",
    },
    list_item_five: {
      en: "Brochures, flyers, catalogs, menus, magazines.",
      es: "Folletos, flyers, catálogos, menus, revistas",
      fr: "Brochures, prospectus, catalogues, menus, revues.",
    },
    list_item_six: {
      en: "Boxes, labels, stickers, calendars, packaging in general.",
      es: "Cajas, etiquetas autoadhesivas, calendarios, packaging en general.",
      fr: "Boîtes, étiquettes, calendriers, emballages en général.",
    },
    list_item_seven: {
      en: "Formal cards and invitations.",
      es: "Tarjetas e invitaciones formales.",
      fr: "Cartes et invitations formelles.",
    },
    intro_outro: {
      en: "Our main goal is to produce simple, memorable, high visual impact designs. We strive for an immediate and long-lasting recognition of your company, business, or brand.",
      es: "Nuestro objetivo principal es producir diseños simples y memorables con fuerte impacto visual. Buscamos que su empresa, negocio o marca tengan un reconocimiento inmediato y duradero.",
      fr: "Notre objet principal es de produire des désigns simples et mémorables qui ont à la fois  un fort impact visuel. Nous cherchons à accorder une reconnaissance immédiate et durable à votre marque ou entreprise.",
    },
    call_me: {
      en: "Call us!",
      es: "Llamenos!",
      fr: "Appellez-nous!",
    },
    home_text: {
      en: "Looking for a creative solution? I would love to help you out with that! I was born an artist, and have been playing with different forms of art all my life: notably, graphic design has been my main focus for many years, having lived and worked as a designer in Buenos Aires, Montreal, Miami and now Punta del Este. On this website you will find some of my works and a few ways to contact me. :) Feel free to share your questions, it's a pleasure to talk!",
      es: "¿Estás buscando una solución creativa? Me encantaría ayudarte con eso. Nací artista y vengo experimentando con distintas formas de arte desde que tengo recuerdo. Entre ellas, el diseño gráfico ha sido uno de mis principales puntos de enfoque: tengo años de experiencia de vida y trabajo en Buenos Aires, Montreal, Miami y ahora Punta del Este. En este sitio, was a encontrar algunos de mis proyectos, y diversas formas de contactarme. :) No dudes en escribirme, ¡es un placer conversar!",
      fr: "Tu cherches une solution qui soit créative? Je suis là pour toi. Depuis que j'ai connaissance, je suis artiste et j'adore jouer avec de nombreuses formes d'art, notamment l'art graphique. J'œuvre dans le monde du design depuis déjà un bon temps, ayant vécu et travaillé à Buenos Aires, Montréal, Miami et mainteneant Punta del Este. Sur ce site web, vous trouverez quelques-uns de mes travaux, ainsi que plusieurs options pour me contacter. :) Posez-moi vos questions, c'est un plaisir de commencer une conversation! ",
    },
    see: {
      en: "See some of our projects ",
      es: "Puedes ver algunos de nuestros proyectos ",
      fr: "Vous pouvez voir quelques-uns de nos projets ",
    },
    here: {
      en: "here.",
      es: "aquí.",
      fr: "ici.",
    },
    designed_by: {
      en: "Designed by Myriam Ekhauzer. ©",
      es: "Diseñado por Myriam Ekhauzer. ©",
      fr: "Conçu par Myriam Ekhauzer. ©",
    },
    digital_design: {
      en: "Digital Design",
      es: "Diseño Digital",
      fr: "Digital Design",
    },
    print_design: {
      en: "Print Design",
      es: "Print Design",
      fr: "Print Design",
    },
    brand_identity: {
      en: "Brand Identity",
      es: "Identidad de Marca",
      fr: "Identité de Marque",
    },
    art_direction: {
      en: "Art Direction",
      es: "Dirección Artística",
      fr: "Direction Artistique",
    },
    digital_design_d: {
      en: "Websites, e-commerce, mobile icons, social media content, newsletters designs & more tailored for your needs.",
      es: "Diseños a medida de sitios web, e-commerce, íconos parar applicaciones, contenido para redes sociales, newsletters, y más.",
      fr: "Conception et design pour votre site web, e-commerce, icône d'application, contenu réseaux sociaux, newsletters, et bien plus.",
    },
    print_design_d: {
      en: "Anything that's printable: flyers, catalogs, magazines, boxes, labels, packaging in general, formal cards and invitations.",
      es: "Cualquier cosa que se pueda imprimir: flyers, catálogos, revistas, cajas, etiquetas, packaging en general, tarjetas e invitaciones formales.",
      fr: "Tout ce que vous pouvez imprimer: prospectus, catalogues, revues, boîtes, étiquettes, emballages en général, cartes et invitations formelles.",
    },
    brand_identity_d: {
      en: "Corporate image, business cards, logos, logotypes, as well interior and exterior designs of all you need to make your brand's visual identity stand out.",
      es: "Imagen corporativa, trajetas, logos, y logotipos. Además, diseños interiores y exteriores para que la identidad visual de su marca sepa destacar.",
      fr: "Image de l'entreprise, cartes professionnelles, logos et logotypes. Design intérieur et exterieur de l'identité visuelle dont votre marque a besoin pour ressortir.",
    },
    art_direction_d: {
      en: "Visual concepts and on-set art direction for photoshoots, events, illustration and animation.",
      es: "Conceptos visuales y dirección artística para eventos, sesiones fotográficas, illustraciones y animación.",
      fr: "Conception visualle et direction artistique pour événements, sessions photographiques, illustrations et animations.",
    },
  };

  const translation = translations[index][language];

  return translation;
};

export const Language = () => {};
