import React from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const Contact = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return (
      <a className="contactoption" href={`mailto:${email}${params}`}>
        {children}
      </a>
    );
  };

  return (
    <div className="background">
      <div className="pagetitle">{i18n({ index: "contact", language })}</div>
      <div className="contactpage">
        <div className="contactoption">
          <WhatsAppIcon style={{ color: "#fa198b" }} />
          <text className="contacttext">+598 96 858 404</text>
        </div>
        <a
          className="contactoption"
          href="https://uy.linkedin.com/in/myriamekhauzer"
        >
          <LinkedInIcon style={{ color: "#fa198b" }} />
          <text className="contacttext">Myriam Ekhauzer</text>
        </a>
        <div>
          <Mailto
            email="myriam.ekhauzer@outlook.com"
            subject="Arte y Design"
            body=""
          >
            <EmailOutlinedIcon style={{ color: "#fa198b" }} />
            <text className="contacttext">myriam.ekhauzer@outlook.com</text>
          </Mailto>
        </div>

        <a className="contactoption" href="https://m.facebook.com/ekhauzer/">
          <FacebookOutlinedIcon style={{ color: "#fa198b" }} />
          <text className="contacttext">Myriam Ekhauzer</text>
        </a>
        <a
          className="contactoption"
          href="https://instagram.com/lamonamyr?igshid=MzRlODBiNWFlZA=="
        >
          <InstagramIcon style={{ color: "#fa198b" }} />
          <text className="contacttext">@lamonamyr</text>
        </a>
      </div>
    </div>
  );
};
