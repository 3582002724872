import React from "react";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";
import { isMobile } from "react-device-detect";
import digital_design from "./../pics/digital_design_.jpeg";
import print_design from "./../pics/print_design_.jpeg";
import brand_identity from "./../pics/brand_identity_.jpeg";
import art_direction from "./../pics/art_direction_.jpeg";
import { Link } from "react-router-dom";

export const Strategy = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  console.log({ settings });

  const MobileView = () => {
    return (
      <div className="strategypage">
        <div className="strategyintro">
          <div> {i18n({ index: "intro_outro", language })}</div>
          <div>
            {i18n({ index: "see", language })}
            <Link to={"/projects"} className="here">
              {" "}
              {i18n({ index: "here", language })}
            </Link>
          </div>
        </div>
        <div className="strategymain">
          <div className="strategyright">
            <img
              className="strategyimgmobile"
              src={digital_design}
              alt=""
            ></img>

            <div className="stextmobile">
              <text className="strategytext">
                {i18n({ index: "digital_design_d", language })}
              </text>
            </div>
          </div>

          <div className="strategyleft">
            <div className="stextmobile">
              <text className="strategytext">
                {i18n({ index: "print_design_d", language })}
              </text>
            </div>
            <img className="strategyimgmobile" src={print_design} alt=""></img>
          </div>

          <div className="strategyright">
            <img
              className="strategyimgmobile"
              src={brand_identity}
              alt=""
            ></img>
            <div className="stextmobile">
              <text className="strategytext">
                {i18n({ index: "brand_identity_d", language })}
              </text>
            </div>
          </div>

          <div className="strategyleft">
            <div className="stextmobile">
              <text className="strategytext">
                {i18n({ index: "art_direction_d", language })}
              </text>
            </div>
            <img className="strategyimgmobile" src={art_direction} alt=""></img>
          </div>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="strategypage">
        <div className="strategyintro">
          <div>{i18n({ index: "intro_outro", language })}</div>
          <div>
            {i18n({ index: "see", language })}
            <Link to={"/projects"} className="here">
              {" "}
              {i18n({ index: "here", language })}
            </Link>
          </div>
        </div>

        <div className="strategymain">
          <div className="strategyright">
            <img className="strategyimg" src={digital_design} alt=""></img>

            <div className="stext">
              <text className="strategytext">
                {i18n({ index: "digital_design_d", language })}
              </text>
            </div>
          </div>

          <div className="strategyleft">
            <div className="stext">
              <text className="strategytext">
                {i18n({ index: "print_design_d", language })}
              </text>
            </div>
            <img className="strategyimg" src={print_design} alt=""></img>
          </div>

          <div className="strategyright">
            <img className="strategyimg" src={brand_identity} alt=""></img>
            <div className="stext">
              <text className="strategytext">
                {i18n({ index: "brand_identity_d", language })}
              </text>
            </div>
          </div>

          <div className="strategyleft">
            <div className="stext">
              <text className="strategytext">
                {i18n({ index: "art_direction_d", language })}
              </text>
            </div>
            <img className="strategyimg" src={art_direction} alt=""></img>
          </div>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
