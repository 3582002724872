import React, { useState } from "react";
import { useUserSettings } from "../user";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import ArrowCircleLeftTwoToneIcon from "@mui/icons-material/ArrowCircleLeftTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "react-device-detect";
import { i18n } from "../i18n";
import { Link } from "react-router-dom";

import mama from "./../pics/projects/mama.webp";
import ometz from "./../pics/projects/ometz.webp";
import dentista from "./../pics/projects/dentista.webp";
import card from "./../pics/projects/card.webp";
import disco from "./../pics/projects/disco.webp";
import menu from "./../pics/projects/menu.webp";
import menu2 from "./../pics/projects/menu2.webp";
import packaging from "./../pics/projects/packaging.webp";
import pulse from "./../pics/projects/pulse.webp";
import relieve from "./../pics/projects/relieve.webp";
import torrecillas from "./../pics/projects/torrecillas.webp";
import cashew from "./../pics/projects/cashew.webp";
import lucyskitchen from "./../pics/projects/lucyskitchen.webp";
import music8 from "./../pics/projects/music8.webp";
import ometzlogo from "./../pics/projects/ometzlogo.webp";
import paramount from "./../pics/projects/paramount.webp";
import pepepino from "./../pics/projects/pepepino.webp";
import raven from "./../pics/projects/raven.webp";
import signaturecare from "./../pics/projects/signaturecare.webp";
import gea from "./../pics/projects/gea.jpg";
import halah from "./../pics/projects/halah.jpg";
import rapiditas from "./../pics/projects/rapiditas.jpg";
import isf from "./../pics/projects/isf.webp";
import phoenix from "./../pics/projects/phoenix.jpg";
import corniche_2 from "./../pics/projects/corniche_2.jpg";
import corniche_3 from "./../pics/projects/corniche_3.jpeg";
import halah_web from "./../pics/projects/halah_web.jpg";

export const Projects = () => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [settings] = useUserSettings();
  const [index, setIndex] = useState(0);
  const [scroll, setScroll] = useState(0);
  const { language } = settings;

  const handleClick = (index) => {
    if (showFullScreen === true) {
      setShowFullScreen(false);
      window.scrollTo({ scrollY: scroll });
    } else {
      setShowFullScreen(true);
      window.scrollTo({ top: 150, behavior: "smooth" });
    }
  };

  const pictures = [
    {
      c: mama,
      t: i18n({ index: "mama_t", language }),
      d: i18n({ index: "mama_d", language }),
    },
    {
      c: music8,
      t: "Logo",
      d: "Music 8 ®",
    },
    {
      c: menu,
      t: i18n({ index: "menu_t", language }),
      d: i18n({ index: "menu_d", language }),
    },
    {
      c: halah,
      t: "Logo",
      d: "Halah ®",
    },
    {
      c: rapiditas,
      t: "Logo",
      d: "Rapiditas",
    },
    {
      c: pepepino,
      t: "Logo",
      d: "Pepepino",
    },
    {
      c: disco,
      t: i18n({ index: "disco_t", language }),
      d: i18n({ index: "disco_d", language }),
    },
    {
      c: ometzlogo,
      t: "Logo",
      d: "Ometz ®",
    },
    {
      c: corniche_3,
      t: i18n({ index: "corniche3_t", language }),
      d: i18n({ index: "corniche3_d", language }),
    },
    {
      c: ometz,
      t: i18n({ index: "ometz_t", language }),
      d: i18n({ index: "ometz_d", language }),
    },
    {
      c: dentista,
      t: i18n({ index: "dentista_t", language }),
      d: i18n({ index: "dentista_d", language }),
    },
    {
      c: gea,
      t: "Logo",
      d: "GEA Real Estate",
    },
    {
      c: card,
      t: i18n({ index: "card_t", language }),
      d: i18n({ index: "card_d", language }),
    },
    {
      c: menu2,
      t: i18n({ index: "menu2_t", language }),
      d: i18n({ index: "menu2_d", language }),
    },
    {
      c: signaturecare,
      t: "Logo",
      d: "Signature Care",
    },
    {
      c: cashew,
      t: "Logo",
      d: "Cashew Trust Company",
    },
    {
      c: pulse,
      t: i18n({ index: "pulse_t", language }),
      d: i18n({ index: "pulse_d", language }),
    },
    {
      c: packaging,
      t: i18n({ index: "packaging_t", language }),
      d: i18n({ index: "packaging_d", language }),
    },
    {
      c: corniche_2,
      t: i18n({ index: "corniche_t", language }),
      d: i18n({ index: "corniche_d", language }),
    },
    {
      c: raven,
      t: "Logo",
      d: "Raven Music",
    },
    {
      c: phoenix,
      t: "Phoenix",
      d: i18n({ index: "phoenix_d", language }),
    },
    {
      c: torrecillas,
      t: i18n({ index: "torrecillas_t", language }),
      d: i18n({ index: "torrecillas_d", language }),
    },
    {
      c: halah_web,
      t: "Halah",
      d: i18n({ index: "halah_d", language }),
      l: "https://www.halah-uy.com/",
    },
    {
      c: isf,
      t: i18n({ index: "card_t", language }),
      d: i18n({ index: "card2_d", language }),
    },
    {
      c: paramount,
      t: "Logo",
      d: "Paramount South Florida",
    },
    {
      c: relieve,
      t: i18n({ index: "relieve_t", language }),
      d: i18n({ index: "relieve_d", language }),
    },
    {
      c: lucyskitchen,
      t: "Logo",
      d: "Lucy's Kitchen",
    },
  ];

  const FullSize = () => {
    return (
      <div className="fullsize">
        <button
          className="iconx"
          onClick={() => {
            handleClick();
          }}
        >
          {" "}
          <CloseIcon style={{ color: "#fff" }} />
        </button>
        {index > 0 ? (
          <button
            className="left_button"
            onClick={() => setIndex((index) => index - 1)}
          >
            <ArrowCircleLeftTwoToneIcon
              style={{ color: "#fa198b", fontSize: 28 }}
            />
          </button>
        ) : (
          <button className="disabled_button">
            <ArrowCircleLeftTwoToneIcon
              style={{ color: "#fa198b", fontSize: 28 }}
            />
          </button>
        )}
        <div className="examplefull">
          <img
            className="picturefull"
            src={pictures[index].c}
            alt=""
            onClick={() => {
              setShowFullScreen(!showFullScreen);
            }}
          ></img>
          <div className="textfull">
            <h4>{pictures[index].t}</h4>
            <text>{pictures[index].d}</text>
            {pictures[index].l ? (
              <a className="textfull" href={pictures[index].l}>
                <h4>{i18n({ index: "visit", language })}</h4>
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
        <button
          className="right_button"
          onClick={() => setIndex((index) => (index < 26 ? index + 1 : 0))}
        >
          <ArrowCircleRightTwoToneIcon
            style={{ color: "#fa198b", fontSize: 28 }}
          />
        </button>
      </div>
    );
  };

  const MobileView = () => {
    return showFullScreen === true ? (
      <FullSize />
    ) : (
      <div className="background_mobile">
        <div className="strategyintro">
          <div className="projectsintro">
            {" "}
            {i18n({ index: "designed_by", language })}
          </div>
          <text className="projects_question">
            {i18n({ index: "question", language })}
            <Link className="contact_link" to={"/contact"}>
              {i18n({ index: "contact_us", language })}
            </Link>
          </text>
        </div>
        <div className="allprojects">
          {pictures.map((picture, index) => {
            return (
              <img
                className="photo_mobile"
                src={pictures[index].c}
                alt=""
                onClick={() => {
                  setIndex(index);
                  // setShowFullScreen(!showFullScreen);
                  handleClick();
                }}
              ></img>
            );
          })}
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return showFullScreen === true ? (
      <FullSize />
    ) : (
      <div className="background_mobile">
        <div className="strategyintro">
          <div className="projectsintro">
            {" "}
            {i18n({ index: "designed_by", language })}
          </div>
          <text className="projects_question">
            {i18n({ index: "question", language })}
            <Link className="contact_link" to={"/contact"}>
              {i18n({ index: "contact_us", language })}
            </Link>
          </text>
        </div>
        <div className="allprojects">
          {pictures.map((picture, index) => {
            return (
              <img
                className="photo"
                src={pictures[index].c}
                alt=""
                onClick={() => {
                  setIndex(index);
                  setScroll(window.scrollY);
                  handleClick();
                  // setShowFullScreen(!showFullScreen);
                  // window.scrollTo({ top: 150, behavior: "smooth" });
                }}
              ></img>
            );
          })}
        </div>
      </div>
    );
  };

  return isMobile ? <MobileView /> : <DesktopView />;
};
